<template>
  <div>
    <title-bar :title-stack="titleStack" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'

export default {
  name: 'BinsList',
  components: {
    TitleBar
  },
  computed: {
    titleStack () {
      return ['Bins']
    }
  }
}
</script>
